export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'No Reg',
      col: 4
    }

  },
  form0: {
    p1: {
      label: 'Tiba di Ruangan (Tanggal)',
      widget: 'wdatenormal',
      col: 3,
      disabled: true,

      data: []
    },
    p2: {
      label: 'Tiba di Ruangan (Jam)',
      widget: 'wtext',
      col: 3,
      disabled: true,

      data: []
    },
    p3: {
      label: 'Pengkajian (Tanggal)',
      widget: 'wdatenormal',
      col: 3,
      disabled: true,

      data: []
    },
    p4: {
      label: 'Pengkajian (Jam)',
      widget: 'wtext',
      col: 3,
      disabled: true,

      data: []
    }
  },
  form1: {
    p1: {
      label: 'Keluhan Utama : ',
      widget: 'wtextarea',
      col: 6,
      data: []
    },
    p2: {
      label: 'Riwayat Penyakit Sekarang : ',
      widget: 'wtextarea',
      col: 6,
      data: []
    },
    p3: {
      label: 'Riwayat Penyakit Dahulu : ',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p4: {
      label: 'a. Riwayat Dirawat/Operasi - Pernah Dirawat',
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6
    },
    p4b: {
      label: 'b. Riwayat Dirawat/Operasi - Pernah Operasi/Tindakan',
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6
    },
    p4c: {
      label: 'c. Riwayat Dirawat/Operasi - Pernah Operasi/Pembiusan',
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6
    },
    p5: {
      label: 'Riwayat Penyakit Keluarga : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ada, yaitu:', value: null, field: 'auto' }
      ],
      col: 6
    },
    p6: {
      label: 'Riwayat Konsumsi Obat Penenang : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ada, yaitu:', value: null, field: 'auto' }
      ],
      col: 6
    },
    p7: {
      label: 'Riwayat Merokok : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, jumlah / hari:', value: null, field: 'auto' }
      ],
      col: 6
    },
    p7a: {
      label: 'Riwayat Merokok (lamanya) : ',
      widget: 'wtext',
      data: null,
      col: 6
    },
    p8: {
      label: 'Riwayat Minum Minuman Keras : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, jumlah / hari:', value: null, field: 'auto' }
      ],
      col: 6
    },
    p8a: {
      label: 'Riwayat Minum Minuman Keras (lamanya) : ',
      widget: 'wtext',
      data: null,
      col: 6
    },
    p9: {
      label: 'Obat dari Rumah : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ada, sebutkan:', value: null, field: 'auto' }
      ],
      col: 6
    },
    p10: {
      label: 'Obat dari Rumah : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Kapan:', value: null, field: 'auto' }
      ],
      col: 6
    },
    p11: {
      label: 'Riwayat Alergi : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ada, yaitu:', value: null, field: 'auto' }
      ],
      col: 6
    },
    p12: {
      label: 'Riwayat Menerima Transfusi Darah : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ya, Waktu Terakhir', value: null, field: 'auto' }
      ],
      col: 6
    },
    p13: {
      label: 'Reaksi yang Timbul : ',
      widget: 'wradio',
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ada,', value: null, field: 'auto' }
      ],
      col: 6
    },
    p14: {
      label: 'Riwayat Pekerjaan (Apakah berhubungan dengan Zat-Zat berbahaya (Kimia, Gas, Radiasi, dll) ?',
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, sebutkan:', value: null, field: 'auto' }
      ],
      col: 6
    },
    l1: {
      label: 'Status Sosial dan Ekonomi : ',
      widget: 'wlabelkiri',
      col: 12,
      data: []
    },
    p15: {
      label: 'Pekerjaan',
      widget: 'wradio',
      data: [
        { text: 'Wiraswasta', value: 'Wiraswasta' },
        { text: 'PNS', value: 'PNS' },
        { text: 'Pensiunan', value: 'Pensiunan' },
        { text: 'IRT', value: 'IRT' },
        { text: 'Mahasiswa', value: 'Mahasiswa' }
      ],
      col: 6
    },
    p16: {
      label: 'Interaksi Sosial',
      widget: 'wradio',
      data: [
        { text: 'Takut terhadap Terapi/Pembedahan/Lingkungan RS', value: 'Takut terhadap Terapi/Pembedahan/Lingkungan RS' },
        { text: 'Marah/Tegang', value: 'Marah/Tegang' },
        { text: 'Sedih', value: 'Sedih' }
      ],
      col: 6
    },
    p17: {
      label: 'Psikologis',
      widget: 'wradio',
      data: [
        { text: 'Tidak Terganggu', value: 'Tidak Terganggu' },
        { text: 'Ada Gangguan', value: 'Ada Gangguan' },
        { text: 'Tidak Membahayakan', value: 'Tidak Membahayakan' },
        { text: 'Membahayakan Diri Sendiri/Orang Lain', value: 'Membahayakan Diri Sendiri/Orang Lain' }
      ],
      col: 6
    }
  },
  form10: {
    p1: {
      label: 'Keadaan Umum',
      widget: 'wradio',
      data: [
        { text: 'Tidak Tampak Sakit', value: 'Tidak Tampak Sakit' },
        { text: 'Tampak Sakit Ringan', value: 'Tampak Sakit Ringan' },
        { text: 'Tampak Sakit Sedang', value: 'Tampak Sakit Sedang' },
        { text: 'Tampak Sakit', value: 'Tampak Sakit' }
      ],
      col: 6
    },
    p2: {
      label: 'Kesadaran',
      widget: 'wradio',
      data: [
        { text: 'CM', value: 'CM' },
        { text: 'Apatis', value: 'Apatis' },
        { text: 'Somnolen', value: 'Somnolen' },
        { text: 'Soporos', value: 'Soporos' },
        { text: 'Koma', value: 'Koma' }
      ],
      col: 6
    },
    l1: {
      label: 'GCS',
      widget: 'wlabelkiri',
      col: 2,
      data: []
    },
    p3: {
      label: 'E',
      widget: 'wtext',
      col: 2,
      data: []
    },
    p4: {
      label: 'M',
      widget: 'wtext',
      col: 2,
      data: []
    },
    p5: {
      label: 'V',
      widget: 'wtext',
      col: 2,
      data: []
    },
    p6: {
      label: 'Keadaan Gizi',
      widget: 'wradio',
      data: [
        { text: 'Baik', value: 'Baik' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Buruk', value: 'Buruk' }
      ],
      col: 4
    },
    l2: {
      label: 'Tanda-Tanda Vital:',
      widget: 'wlabelkiri',
      data: '',
      col: 2
    },
    p7: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TD', suffix: 'mmHg', type: 'string' } }
    },
    p8: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'hr', suffix: 'x/mnt', type: 'string' } }
    },
    p9: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'RR', suffix: 'x/mnt', type: 'string' } }
    },
    p10: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'T', suffix: '°C', type: 'string' } }
    },
    p11: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'SpO2', suffix: '%', type: 'string' } }
    }
  },
  form4: {
    p1: {
      label: 'Pemeriksaan Penunjang Pre-Rawat Inap',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p2: {
      label: 'Diagnosa Kerja',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p3: {
      label: 'Diagnosa Banding',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p4: {
      label: 'Perencanaan Pelayanan (Terapi, Tindakan, Konsultasi, Pemeriksaan Penunjang, Edukasi, dsb)',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p5: {
      label: 'Rencana Pulang :',
      widget: 'wtext',
      col: 4,
      data: [],
      property: { attrs: { suffix: 'hari', type: 'string' } }
    },
    p6: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Hari', value: 'Hari' },
        { text: 'Tidak Dapat Diprediksi', value: 'Tidak Dapat Diprediksi' }
      ],
      col: 4
    }
  }
}
